<template>
  <div v-if="getAccountDetail" class="account-detail-wrap">
    <div class="back-to-list-text cus-is-button" @click="$router.go(-1)">
      <el-icon style="margin-right: 18px"><Back /></el-icon>
      返回列表
    </div>
    <div class="account-table">
      <div class="item">
        <p>帳號</p>
        <p>{{ getAccountDetail.user_id }}</p>
      </div>
      <div class="item">
        <p>類別</p>
        <p>{{ getAccountDetail.permission_id === 1 ? '店長' : '店員' }}</p>
      </div>
      <div class="item">
        <p>可登入門市</p>
        <p>{{ getAccountDetail.store_info.map((e) => e.shop_name).join('、') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router';
import { watch } from 'vue';
import { useGetters, useMutations } from '@/utils/mapStore';
export default {
  name: 'account-detail',
  setup() {
    const router = useRouter();
    const route = useRoute();
    //偵測沒有資料
    const getAccountDetail = useGetters('account', ['getAccountDetail']);
    const CLEAR_ACCOUNT_DETAIL = useMutations('account', ['CLEAR_ACCOUNT_DETAIL']);
    if (!getAccountDetail.value) router.push('/account/list?page=1');
    watch(route, () => {
      if (!route.fullPath.includes('/detail')) CLEAR_ACCOUNT_DETAIL();
    });
    return { getAccountDetail };
  },
};
</script>
<style lang="scss" scoped>
.account-detail-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: scroll;
  @include white-bg;
  padding: 22px;
}
.button-wrap {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.account-table {
  margin: 40px 0;
  .item {
    display: grid;
    grid-template-columns: 120px auto;
    height: 40px;
    p:nth-of-type(1) {
      font-weight: 500;
    }
    p:nth-of-type(2) {
      color: $color-text-light;
    }
  }
}
</style>
